import * as React from "react";
import styled from 'styled-components';

import Container from "../../atoms/Container/Container";
import media from "../../utilities/media";

type ISeparator = {
  image: Element
}

const SeparatorStyled = styled.div`
  margin:80px 0 80px;
  
  ${media.moreThan("md")} {
    margin:100px 0 80px;
  }
`;

const SeparatorContent = styled.div`
  height: 400px;
`;

const Separator = ({
  image,
}: ISeparator) => (
  <SeparatorStyled>
    <Container
      bg={image}
      bgMobile
      bgOverlay
    >
      <SeparatorContent/>
    </Container>
  </SeparatorStyled>
)

export default Separator;
