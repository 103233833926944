import * as React from "react";
import {ReactNode, useRef, useState} from "react";
import styled from 'styled-components';

import Container from "../../atoms/Container/Container";
import SectionHeader from "../../atoms/SectionHeader/SectionHeader";
import LicenseDot, {LicenseDotStyled} from "../../atoms/LicenseDot/LicenseDot";
import media from "../../utilities/media";

import {copy} from "../../typography";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";

type ITextSection = {
  headline: ReactNode,
  image: ReactNode,
  children?: ReactNode,
}

const TextSectionStyled = styled.div`
  margin:0;
`;

const TextContent = styled.div`
  border-radius: 7px;
  box-shadow: rgba(0,0,0,0.1) 0 0 6px 0;
  background-color: #F7F7F7;
  padding: 40px 10px;
  width: 100%;
  
  ${media.moreThan("md")} {
    padding: 40px 100px;
  }
`;

type ISteps = {
  showSteps: boolean,
}

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepArrow = styled.div`
  width: 60px;
  height: 40px;
  position: relative;
  
  ${media.lessThan("md")} {
    transform: scale(0.6) rotate(90deg);
    margin-top:10px;
    margin-bottom:30px;
  }
  
  ${media.moreThan("md")} {
    margin-top:35px;
    margin-left:20px;
    margin-right:20px;
  }
  
  &:before {
    content:"";
    display: block;
    position: absolute;
    top: 17px;  
    height:0;
    width: 100%;
    border-top: 4px solid #515151;
  }
  
  &:after {
    content:"";
    display: block;
    position: absolute;
    top:5px;
    right:2px;
    width: 28px;
    height: 28px;
    border-top: 4px solid #515151;
    border-right: 4px solid #515151;
    transform: rotate(45deg);
  }
`;

const Steps = styled.div<ISteps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${media.moreThan("md")} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  
  ${Step}, ${StepArrow} {
    transition: opacity 0.5s ease-in-out 0s;
    opacity: ${props => (props.showSteps ? 1 : 0)};

    &:nth-child(2) {
        transition: opacity 0.5s ease-in-out 0.2s;
    }
    
    &:nth-child(3) {
        transition: opacity 0.5s ease-in-out 0.4s;
    }

    &:nth-child(4) {
        transition: opacity 0.5s ease-in-out 0.6s;
    }

    &:nth-child(5) {
        transition: opacity 0.5s ease-in-out 0.8s;
    }
  }
  
`;

const StepTitle = styled.p`
  ${copy};
  text-align: center;
`;

const TextSection = ({
  headline,
  image,
  children,
}: ITextSection) => {
  const [elementPosition, setElementPosition] = useState(0)
  const elementRef = useRef()

  // Element scroll position
  useScrollPosition(
    ({ currPos }) => {
      setElementPosition(currPos.y)
    }, [], elementRef
  )

  const showSteps = elementPosition < (typeof window !== 'undefined' ? window.innerHeight : 600) - 100;

  return (
    <TextSectionStyled>
      <Container
        bg={image}
      >
        <SectionHeader>
          {headline}
        </SectionHeader>
        <TextContent>
          <Steps ref={elementRef} showSteps={showSteps}>
            <Step>
              <LicenseDot mobileSmall variant="A1">A1</LicenseDot>
              <StepTitle>11 kW (15 PS)<br/>
                Mindestalter 16 Jahre</StepTitle>
            </Step>
            <StepArrow/>
            <Step>
              <LicenseDot mobileSmall variant="A2">A2</LicenseDot>
              <StepTitle>
                35 kW (48 PS)<br/>
                Mindestalter 18 Jahre
              </StepTitle>
            </Step>
            <StepArrow/>
            <Step>
              <LicenseDot mobileSmall variant="A">A</LicenseDot>
              <StepTitle>
                Keine Beschränkung<br/>
                Zweirad: 20 Jahre<br/>
                Dreirad: 21 Jahre
              </StepTitle>
            </Step>
          </Steps>
          {children}
        </TextContent>
      </Container>
    </TextSectionStyled>
  );
}

export default TextSection;
