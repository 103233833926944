import * as React from "react";
import {ReactNode, useState} from "react";
import styled from 'styled-components';

import Container from "../../atoms/Container/Container";
import SectionHeader from "../../atoms/SectionHeader/SectionHeader";

type IFaqSection = {
  headline: ReactNode,
  children?: ReactNode,
}

const FaqSectionStyled = styled.div`
  margin:0 0 80px;
`;

const FaqContent = styled.div`
  padding: 0;
  width: 100%;
`;

const FaqSection = ({
  headline,
  children,
}: IFaqSection) => {
  const childList = React.Children.toArray(children);

  const [openIndex, setOpenIndex] = useState(-1);

  return (
    <FaqSectionStyled>
      <Container
        width={970}
      >
        <SectionHeader>
          {headline}
        </SectionHeader>
        <FaqContent>
          {childList.map((faq, index) => {
            // @ts-ignore
            return React.cloneElement(faq, {
                open: openIndex === index,
                onClick: () => {
                  if (openIndex === index) {
                    setOpenIndex(-1);
                  } else {
                    setOpenIndex(index);
                  }
                },
              });
          })}
        </FaqContent>
      </Container>
    </FaqSectionStyled>
  );
}

export default FaqSection;
