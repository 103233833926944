import * as React from "react";
import styled from 'styled-components';
import {ReactNode, useRef, useState} from "react";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";

import media from "../../utilities/media";

type IContainer = {
  children: ReactNode,
  width?: number,
  bg?: ReactNode,
  bgMobile?: boolean,
  bgOverlay?: boolean,
  bgFull?: boolean,
  contentPadding?: boolean,
}

const ContainerStyled = styled.div`
  position: relative;
  overflow: hidden;
  z-index:1;
`;

const ContainerBg = styled.div`
  position: absolute;
  z-index:-1;
  top:0;
  left:0;
  width: 100%;
  overflow:hidden;
  
  ${props => (props.full ? `
    height: 100%;
  ` : `
    height: 960px;
    max-height: 960px;
  `)}
  
  img, video {
    width: 100%;
  }
  
  ${media.lessThan("lg")} {
    ${props => props.mobile ? null : 'display:none'};
  }
  
  &:before {
    content: "";
    ${props => props.overlay ? 'display:block' : 'display: none'};
    position: absolute;
    z-index:10;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.6);
  }
`;

const ContainerContent = styled.div`
  width: 100%;
  max-width: ${props => props.width}px;
  margin: 0 auto;
  
  ${props => (props.padding ? 'padding:0 20px' : 'padding:0')};
  
  img {
    max-width: 100%;
  }
`;

const Container = ({
  children,
  width,
  bg,
  bgMobile,
  bgOverlay,
  bgFull,
  contentPadding,
}: IContainer) => {

  const [elementPosition, setElementPosition] = useState(0)
  const elementRef = useRef()

  // Element scroll position
  useScrollPosition(
    ({ currPos }) => {
      setElementPosition(currPos.y)
    }, [], elementRef
  )

  return (
    <ContainerStyled
      ref={elementRef}
    >
      {bg ? (
        <ContainerBg
          style={{
            transform: `translate3d(0,${elementPosition*-0.3}px, 0)`
          }}
          mobile={bgMobile}
          overlay={bgOverlay}
          full={bgFull}
        >
          {bg}
        </ContainerBg>
      ) : null}
      <ContainerContent
        width={width}
        padding={contentPadding}
      >
        {children}
      </ContainerContent>
    </ContainerStyled>
  );
}

Container.defaultProps = {
  width: 1400,
  bg: null,
  bgMobile: false,
  bgOverlay: true,
  bgFull: false,
  contentPadding: true,
}

export default Container;
