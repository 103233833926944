import styled from 'styled-components';

import media from "../../utilities/media";

const SectionHeader = styled.div`
  padding-top: 0;
  padding-bottom: 0;
  
  ${media.moreThan("lg")} {
    padding-top: 40px;
    padding-bottom: 60px;
  }
`;

export default SectionHeader;
