import media from "./utilities/media";

import {css} from 'styled-components';

export const copy = css`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 28px;
  font-weight: normal;
  color: #1F1F1F;
  
  p {
    margin:0 0 1em;
    
    &:last-child {
      margin-bottom:0;
    }
  }
  
  h3 {
    margin:1em 0 0;
  }
  
  ul {
    margin:0 0 1em;
    list-style-type: none;
    padding-left:40px;
    
    li {
      position: relative;
      &:before {
        content:"";
        display: block;
        background-color: #1F1F1F;
        border-radius: 50%;
        width:3px;
        height:3px;
        position: absolute;
        top:12px;
        left:-30px;
      }
    }
  }
`;

export const headline1 = css`
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: normal;
  line-height: 1.2;
  color: #1F1F1F;

  ${media.moreThan("md")} {
    font-size: 52px;
  }

`;

export const headline2 = css`
  font-family: Montserrat, sans-serif;
  font-size: 26px;
  font-weight: normal;
  line-height: 1.2;
  color: #1F1F1F;
  margin-top:40px;
  margin-bottom: 10px;
`;

export const headline3 = css`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  color: #1F1F1F;
  margin-top:0;
  margin-bottom:1em;
`;

export const footertext = css`
  font-family: Montserrat, sans-serif;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  color: #1F1F1F;
  
  ${media.moreThan("md")} {
    font-size: 12px;
  }
`;
