import * as React from "react";
import styled from 'styled-components';
import media from "../../utilities/media";

import {ReactNode} from "react";
import Container from "../../atoms/Container/Container";

// @ts-ignore
import coverImage from "../../../images/cover.jpg";


type ICoverSection = {
  video: string,
  children?: ReactNode,
}

const CoverSectionStyled = styled.div`
  margin:0;
  z-index:-1;
`;

const CoverContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding:50px 0;

    img {
      display: block;
      height:100%;
    }
  
  ${media.moreThan("md")} {
    height: 80vh;
    max-height: 70vw;
    padding:100px 0;
    
  }
`;

const VideoContainer = styled.div`
  min-width: 100%;
  min-height:100%;
  //padding-bottom: 56.25%;
  position: relative;
  background-color: #000;
  background-image:url("${coverImage}");
`;

const Iframe = styled.iframe`
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  //min-width:100%;
  //min-height:100%;
  width: 100vw;
  height: 56.25vw;
  min-height: 80vh;
  min-width: 142.216vh;
`;

const Video = styled.video`
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  //min-width:100%;
  //min-height:100%;
  width: 100vw;
  height: 56.25vw;
  min-height: 80vh;
  min-width: 142.216vh;
`;

const CoverSection = ({
  video,
  children,
}: ICoverSection) => (
  <CoverSectionStyled>
    <Container
      bgMobile
      bgOverlay={false}
      bgFull
      bg={<VideoContainer>
        <Video autoPlay muted loop>
          <source src={video} type="video/mp4"/>
        </Video>
      </VideoContainer>}
    >
      <CoverContent>
        {children}
      </CoverContent>
    </Container>
  </CoverSectionStyled>
)

CoverSection.defaultProps = {
  teaser: null,
}

export default CoverSection;
