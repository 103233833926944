import {css} from 'styled-components';

export const bgVariants = css`
  ${props => (props.variant === 'AM' ? `
    background-image: linear-gradient(#93C7D2, #689CA4);
  ` : null)};

  ${props => (props.variant === 'A1' ? `
    background-image: linear-gradient(#BFDA91, #88DA8F);
  ` : null)};

  ${props => (props.variant === 'A2' ? `
    background-image: linear-gradient(#787FF6, #1CA7EC);
  ` : null)};

  ${props => (props.variant === 'A' ? `
    background-image: linear-gradient(#EFA282, #C46083);
  ` : null)};
`;

export const bgVariantsDark = css`
  ${props => (props.variant === 'AM' ? `
    background-image: linear-gradient(#93C7D2, #689CA4);
  ` : null)};

  ${props => (props.variant === 'A1' ? `
    background-image: linear-gradient(#9ac551, #61ce6a);
  ` : null)};

  ${props => (props.variant === 'A2' ? `
    background-image: linear-gradient(#787FF6, #1CA7EC);
  ` : null)};

  ${props => (props.variant === 'A' ? `
    background-image: linear-gradient(#EFA282, #C46083);
  ` : null)};
`;
