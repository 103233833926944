import * as React from "react";
import styled from 'styled-components';
import media from "../../utilities/media";

import {ReactNode} from "react";
import {ILicenseTeaser} from "../LicenseTeaser/LicenseTeaser";
import LicenseDot from "../../atoms/LicenseDot/LicenseDot";

export type ILicense = {
  teaser: ILicenseTeaser,
  children?: ReactNode,
}

const LicenseStyled = styled.div`
  position: relative;
  padding-top:100px;

  ${media.moreThan("lg")} {
    padding-top:0;
  }
`;

const LicenseDotContainer = styled.div`
  position: absolute;
  top:10px;
  left: 50%;
  transform: translateX(-50%);
 
  ${media.moreThan("lg")} {
    display: none;
  }
`;

const License = ({
  teaser,
  children,
}: ILicense) => {
  return (
    <LicenseStyled>
      <LicenseDotContainer>
        <LicenseDot variant={teaser.variant}>
          {teaser.short}
        </LicenseDot>
      </LicenseDotContainer>
      {children}
    </LicenseStyled>
  );
}

export default License;
