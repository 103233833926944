import * as React from "react";
import styled from 'styled-components';

import Headline from "../../atoms/Headline/Headline";
import Text from "../../atoms/Text/Text";
import {ReactNode} from "react";
import Container from "../../atoms/Container/Container";

import media from "../../utilities/media";

// @ts-ignore
import arrow from "../../../images/icon_arrow.svg";

import {bgVariantsDark} from '../../cssutils';
import {headline2} from '../../typography';

type IText = {
  headline: string,
  children?: ReactNode,
  teaser?: {
    text: string,
    link: string,
    variant: string,
  }
}

const IntroStyled = styled.div`
  padding:150px 0 0;
  z-index:10;
  position: relative;
  overflow: visible;
  
  ${media.moreThan("md")} {
    padding:100px 0 80px;
  }
`;

const Teaser = styled.a`
  display: block;
  ${bgVariantsDark};
  
  padding: 20px;
  ${headline2};
  text-decoration: none;
  color: #fff;
  width: 100%;
  max-width: 460px;
  border-radius: 7px;
  
  position: absolute;
  z-index:100;
  top:20px;
  left:50%;
  margin:0;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;

  ${media.moreThan("md")} {
    top:-100px;    
  }
  
  ${media.lessThan("md")} {
    font-size: 18px;   
    max-width: 90vw; 
  }
  
  &:hover {
    transform: translateX(-50%) scale(1.05);
  }

`;

const TeaserLink = styled.div`
  font-size: 14px;
  display: block;
  padding: 10px 30px 0 0;
  text-align: right;
  position: relative;
  
  &:after {
    content: "";
    display: block;
    position: absolute;
    right:0;
    top: 12px;
    width: 18px;
    height: 13px;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-size: cover;
  }
  
`;


const IntroSection = ({
  headline,
  children,
  teaser,
}: IText) => (
  <IntroStyled>
    {teaser ? (
      <Teaser href={teaser.link} variant={teaser.variant}>
        {teaser.text}
        <TeaserLink>Mehr Informationen</TeaserLink>
      </Teaser>
    ) : null}
    <Container
      width={700}
    >
      <Headline>
        {headline}
      </Headline>
      <Text>
        {children}
      </Text>
    </Container>
  </IntroStyled>
)

export default IntroSection;
