import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import TinyCrossfade from "react-tiny-crossfade";
import styled from 'styled-components';
import Select from 'react-select';
import Container from "../../atoms/Container/Container";
import LicenseTeaser, {LicenseTeaserStyled} from "../../molecules/LicenseTeaser/LicenseTeaser";
import {ILicense} from "../../molecules/License/License";
import useWindowWidth from "../../utilities/windowwidth/windowwidth";
import media from "../../utilities/media";
import SectionHeader from "../../atoms/SectionHeader/SectionHeader";
import { copy } from "../../typography";

type ILicenseSection = {
  headline: ReactNode,
  children?: Array<ILicense>,
  image: Element
}

const LicenseSectionStyled = styled.div`
  margin:80px 0 80px;
  
  ${media.moreThan("md")} {
    margin:100px 0 80px;
  }
  
  .component-wrapper {
    transition: height 0.5s;
    overflow: hidden;
  }
  
  .before-enter {
    opacity: 0;
  }
  
  .entering {
    opacity: 1;
    transition: opacity 0.5s;
  }
  
  .before-leave {
    opacity: 1;
  }
  
  .leaving {
    opacity: 0;
    transition: opacity 0.5s;
  }
`;

const LicenseMenu = styled.div`
  ${media.moreThan("lg")} {
    display: none;
  }
`;

const SelectLabel = styled.label`
  span {
    ${copy};
  }
`;

const LicenseSelect = styled(Select)`
  ${copy};
  
  .react-select__control {
    margin-bottom:1em;
    
    &.react-select__control--is-focused {
      border-color: #999;
      box-shadow: 0 0 0 1px #999;
    }
  }
  
  .react-select__menu {

    .react-select__option {
  
      &:hover, &.react-select__option--is-focused {
        background-color: #ddd;
        color: #FFF;
      }

      &.react-select__option--is-selected {
        background-color: #999;
        color: #FFF;
      }

      &.react-select__option--is-disabled {
        background-color: #999;
        color: #FFF;
      }
    }
  }

`;

const LicenseTeaserList = styled.div`
  display: none;
  justify-content: center;
  //align-items: stretch;
  flex-wrap: wrap;
  margin:0 -15px;

  ${media.moreThan("lg")} {
    display: flex;
  }
`;

const ListItem = styled.div`
  width: 100%;
  //height: 100%;
  padding:60px 15px 30px;
  max-width: 320px;
  flex: 1 1 auto;
  
  ${LicenseTeaserStyled} {
    height: 100%;
  }
  
  ${media.moreThan("md")} {
    width: calc(100% / 2);
  }

  ${media.moreThan("lg")} {
    width: calc(100% / 3);
  }

  ${media.moreThan("lg")} {
    width: calc(100% / 5);
  }

`;

const LicenseText = styled.div`
  border-radius: 7px;
  box-shadow: rgba(0,0,0,0.1) 0 0 6px 0;
  background-color: #F7F7F7;
  padding: 0 10px 40px;
  margin:0 0 30px;
  width: 100%;
  
  ${media.moreThan("md")} {
    padding: 40px 100px;
  }
`;

export const nextQuotient = (divisor: number, min: number, max: number) => {
  for (let i = min; i <= max; i++) {
    if ((i + 1) % divisor == 0)
      // Add 1 as min is 0-based
      return Math.min(i, max - 1);
  }
  return max - 1;
};

const LicenseSection = ({
  headline,
  children,
  image,
}: ILicenseSection) => {
  const childList = React.Children.toArray(children);

  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [insertionIndex, setInsertionIndex] = useState(-1);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    let nextDetailIndex = activeIndex;

    if (media.isMoreThan("md")) {
      //Two in a row
      nextDetailIndex = nextQuotient(2, activeIndex, childList.length);
    }

    if (media.isMoreThan("lg")) {
      //Three in a row
      nextDetailIndex = nextQuotient(3, activeIndex, childList.length);
    }

    if (media.isMoreThan("xl")) {
      //Three in a row
      nextDetailIndex = childList.length -1;
    }

    setInsertionIndex(nextDetailIndex);
  }, [windowWidth, activeIndex]);

  const hashChange = () => {
    const { hash } = window.location;
    if (hash.indexOf('#license-') !== -1) {
      const index = parseInt(hash.replace('#license-', ''), 10);
      setActiveIndex(index)
    }
  };

  useEffect(() => {
    hashChange();
    window.addEventListener('hashchange', hashChange, false);
    return () => {
      window.removeEventListener('hashchange', hashChange, false);
    };
  }, []);

  const classes = [
    { value: 'license-0', label: 'Klasse AM: Kleinkrafträder/-roller', },
    { value: 'license-1', label: 'Klasse A1 mit B: Leichtkrafträder/-roller', },
    { value: 'license-2', label: 'Klasse A1: Leichtkrafträder/-roller', },
    { value: 'license-3', label: 'Klasse A2: Limitierte Krafträder/-roller', },
    { value: 'license-4', label: 'Klasse A: Offene Krafträder/-roller', },
  ]

  const currentValue = activeIndex!==-1 ? classes[activeIndex] : null;

  return (
    <LicenseSectionStyled>
      <Container
        bg={image}
      >
        <SectionHeader>
          {headline}
        </SectionHeader>
        <div>
          {childList.map((license, index) => (
            <div id={`license-${index}`} key={`license-${index}`}/>
          ))}
        </div>
        <LicenseMenu>
          <SelectLabel htmlFor="license">
            <span>Führerscheinklasse auswählen:</span>
            <LicenseSelect
              inputProps={{ id: 'license'}}
              placeholder="Lizenz"
              isSearchable={false}
              classNamePrefix="react-select"
              options={classes}
              getOptionValue={({ value }) => value}
              value={currentValue}
              onChange={(selection) => {
                location.hash = selection.value;
              }}
            />
          </SelectLabel>
        </LicenseMenu>
        <LicenseTeaserList>
          {childList.map((license, index) => {
            // @ts-ignore
            const teaserInfo = license.props.teaser;
            return (
              <React.Fragment key={`license-${index}`}>
                <ListItem>
                  <LicenseTeaser
                    {...teaserInfo}
                    id={`license-${index}`}
                    onMouseOver={() => setHoverIndex(index)}
                    onMouseOut={() => setHoverIndex(-1)}
                    onClick={(event) => {
                      // Nothing
                    }}
                    active={hoverIndex !== -1 ? hoverIndex === index : activeIndex === index}
                  />
                </ListItem>
              </React.Fragment>
            );
          })}
        </LicenseTeaserList>
        <LicenseText key={'details'}>
          <TinyCrossfade className="component-wrapper" duration={500}>
            {childList.filter((child, index) => activeIndex === index).map((license, index) => (
              license
            )).pop()}
          </TinyCrossfade>
        </LicenseText>
      </Container>
    </LicenseSectionStyled>
  );
}

export default LicenseSection;
