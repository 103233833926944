import * as React from "react";
import styled from 'styled-components';

import media from "../../utilities/media";


export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top:0;
  right:30px;
  height: 95px;

  ${media.lessThan("lg")} {
    position: static;
  }
  
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  width: 80px;
  height: 55px;
  padding: 15px 25px;
  
  svg {
    width: 100%;
    max-height: 100%;
  }
  
  .cls-1 {
    fill: #aaa;
  }
  
  &:hover .cls-1 {
    fill: #666;
  }
  
  .cls-2 {
    fill: #fff;
  }
  
  .cls-3 {
    mask:url(#mask);
  }
`;
export const SocialMenu = () => (
  <SocialContainer>
    <SocialLink href="https://www.facebook.com/VivaLaMopped" target="_blank" rel="noopener">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.62199 218.79501">
        <g data-name="Ebene 2">
          <g data-name="Layer 1">
            <path className="cls-1"
                  d="M73.751,218.795V118.992H107.25l5.016-38.895H73.751V55.265c0-11.261,3.127-18.935,19.275-18.935l20.596-.009V1.533A275.32526,275.32526,0,0,0,83.61,0C53.915,0,33.585,18.126,33.585,51.413V80.097H0v38.895H33.585v99.803Z"/>
          </g>
        </g>
      </svg>
    </SocialLink>
    <SocialLink href="https://www.youtube.com/user/vivalamoppedmotorrad" target="_blank" rel="noopener">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.80001 109.20001">
        <g data-name="Ebene 2">
          <g data-name="Layer 1">
            <path className="cls-1"
                  d="M152.4,17.1A19.62059,19.62059,0,0,0,138.6,3.3C126.5,0,77.8,0,77.8,0S29.1.1,17,3.4A19.62059,19.62059,0,0,0,3.2,17.2C0,29.3,0,54.6,0,54.6S0,79.9,3.3,92.1a19.62059,19.62059,0,0,0,13.8,13.8c12.1,3.3,60.8,3.3,60.8,3.3s48.7,0,60.8-3.3a19.62061,19.62061,0,0,0,13.8-13.8c3.3-12.1,3.3-37.5,3.3-37.5S155.7,29.3,152.4,17.1Z"/>
            <polygon className="cls-2" points="62.3 78 102.7 54.6 62.3 31.2 62.3 78"/>
          </g>
        </g>
      </svg>
    </SocialLink>
    <SocialLink href="https://www.instagram.com/vivalamopped/" target="_blank" rel="noopener">
      <svg xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 34.98806 34.98806">
        <defs>
          <mask x="0" y="0" width="34.98806" height="34.98806" maskUnits="userSpaceOnUse">
            <g>
              <polygon className="cls-2" points="0 0 34.988 0 34.988 34.984 0 34.984 0 0"/>
            </g>
          </mask>
        </defs>
        <g data-name="Ebene 2">
          <g data-name="Ebene 1">
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path className="cls-1"
                            d="M17.49406,0c-4.75112,0-5.34687.02014-7.2128.10528A12.83827,12.83827,0,0,0,6.03478.91845,8.57491,8.57491,0,0,0,2.93617,2.93617,8.57491,8.57491,0,0,0,.91845,6.03478a12.83827,12.83827,0,0,0-.81317,4.24648C.02014,12.14719,0,12.74294,0,17.49406s.02014,5.34681.10528,7.21274a12.83832,12.83832,0,0,0,.81317,4.24648,8.57479,8.57479,0,0,0,2.01772,3.0986,8.57593,8.57593,0,0,0,3.09861,2.0178,12.84052,12.84052,0,0,0,4.24648.8131c1.86593.08514,2.46168.10528,7.2128.10528s5.34681-.02014,7.21273-.10528a12.84058,12.84058,0,0,0,4.24649-.8131,8.94537,8.94537,0,0,0,5.1164-5.1164,12.84057,12.84057,0,0,0,.8131-4.24648c.08514-1.86593.10528-2.46168.10528-7.21274s-.02014-5.34687-.10528-7.2128a12.84052,12.84052,0,0,0-.8131-4.24648,8.57593,8.57593,0,0,0-2.0178-3.09861A8.57479,8.57479,0,0,0,28.95328.91845,12.83833,12.83833,0,0,0,24.70679.10528C22.84087.02014,22.24512,0,17.49406,0Zm0,3.15207c4.67106,0,5.22438.01785,7.06906.102a9.67847,9.67847,0,0,1,3.24839.60235A5.41934,5.41934,0,0,1,29.823,5.16508a5.41934,5.41934,0,0,1,1.30865,2.01147,9.67822,9.67822,0,0,1,.60234,3.24839c.08417,1.84467.102,2.398.102,7.06912s-.01785,5.22438-.102,7.06906a9.67828,9.67828,0,0,1-.60234,3.24839,5.7937,5.7937,0,0,1-3.32012,3.32012,9.67847,9.67847,0,0,1-3.24839.60235c-1.8444.08416-2.39765.102-7.06906.102s-5.22465-.01785-7.06912-.102a9.67841,9.67841,0,0,1-3.24839-.60235A5.41934,5.41934,0,0,1,5.16508,29.823a5.42021,5.42021,0,0,1-1.30865-2.01147,9.67847,9.67847,0,0,1-.60235-3.24839c-.08416-1.84468-.102-2.398-.102-7.06906s.01785-5.22445.102-7.06912a9.67841,9.67841,0,0,1,.60235-3.24839A5.41934,5.41934,0,0,1,5.16508,5.16508,5.41934,5.41934,0,0,1,7.17655,3.85643a9.67841,9.67841,0,0,1,3.24839-.60235c1.84467-.08416,2.398-.102,7.06912-.102Z"/>
                    </g>
                  </g>
                </g>
                <path className="cls-1"
                      d="M17.49406,23.32537a5.83134,5.83134,0,1,1,5.83131-5.83131A5.83131,5.83131,0,0,1,17.49406,23.32537Zm0-14.81476a8.98342,8.98342,0,1,0,8.98338,8.98345,8.98339,8.98339,0,0,0-8.98338-8.98345Z"/>
                <path className="cls-1"
                      d="M28.93169,8.15569a2.09929,2.09929,0,1,1-2.09932-2.09932,2.09927,2.09927,0,0,1,2.09932,2.09932"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SocialLink>
  </SocialContainer>
);
