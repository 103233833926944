import * as React from "react";
import styled from 'styled-components';

import {headline2} from '../../typography';
import {ReactNode} from "react";

type IHeadline = {
  children?: ReactNode,
}

export const Headline2Styled = styled.h1`
  ${headline2}
`;

const Headline2 = ({
  children,
}: IHeadline) => (
  <Headline2Styled>
    {children}
  </Headline2Styled>
)

export default Headline2;
