import * as React from "react";
import styled from 'styled-components';

import Headline2, {Headline2Styled} from "../../atoms/Headline2/Headline2";
import Text from "../../atoms/Text/Text";
import LicenseDot from "../../atoms/LicenseDot/LicenseDot";
import { bgVariants } from "../../cssutils";

export type ILicenseTeaser = {
  short: string,
  variant: "AM"|"A1"|"A2"|"A",
  headline: string,
  description: string,
  active?: boolean,
  onClick?: (event:React.MouseEvent<HTMLElement>) => void,
  onMouseOver?: () => void,
  onMouseOut?: () => void,
  id: string,
}

export const LicenseTeaserStyled = styled.a`
  display: block;
  position: relative;
  width: 100%;
  border-radius: 7px;
  background-color: #FBFBFB;
  text-align: center;
  box-shadow: rgba(0,0,0,0.1) 0 0 6px 0;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
`;

const LicenseMarker = styled.div`
  position: absolute;
  left: 50%;
  top: -55px;
  z-index:3;
  margin-left: -55px;
`;

const TeaserBorder = styled.div`
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:1;
  border-radius: 7px;
  padding: 10px;
  ${bgVariants};
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => (props.show ? '1' : 0)};
`;

const TeaserContent = styled.div`
  position: relative;
  z-index:2;
  padding: 75px 5px 20px;
  width: 100%;
  height: 100%;
  background-color: #FBFBFB;
  
  ${Headline2Styled} {
    margin-top:0;
  }
`;

const LicenseTeaser = ({
  short,
  variant,
  headline,
  description,
  active,
  onClick,
  onMouseOver,
  onMouseOut,
  id,
}: ILicenseTeaser) => {
  return (
    <LicenseTeaserStyled onMouseOver={onMouseOver} onMouseOut={onMouseOut} onClick={onClick} href={`#${id}`}>
      <LicenseMarker>
        <LicenseDot variant={variant}>
          {short}
        </LicenseDot>
      </LicenseMarker>
      <TeaserBorder variant={variant} show={active}/>
      <TeaserContent>
        <Headline2>{headline}</Headline2>
        <Text>{description}</Text>
      </TeaserContent>
    </LicenseTeaserStyled>
  );
}

LicenseTeaser.defaultProps = {
  active: false,
  onClick: () => {},
  onMouseOver: () => {},
  onMouseOut: () => {},
}

export default LicenseTeaser;
