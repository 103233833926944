import {useEffect, useState} from 'react';

const useWindowWidth = () => {
  const currentWidth = typeof window !== 'undefined' ? window.innerWidth: 1200;

  const [width, setWidth] = useState(currentWidth);

  useEffect(() => {
    const currentWidth = typeof window !== 'undefined' ? window.innerWidth: 1200;
    const handleResize = () => setWidth(currentWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
}

export default useWindowWidth;
