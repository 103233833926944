import * as React from "react";
import styled from 'styled-components';
import media from "../../utilities/media";

import {ReactNode} from "react";

import {bgVariants} from '../../cssutils';

type ILicenseDot = {
  children?: ReactNode,
  variant: "AM"|"A1"|"A2"|"A",
  mobileSmall?: boolean,
}

export const LicenseDotStyled = styled.div`
  width: 109px;
  height: 109px;  
  border-radius: 50%;
  
  ${media.lessThan("md")} {
    ${props => (props.mobileSmall ? `
      width: 75px;
      height: 75px;
      font-size: 30px;
    ` : null)}
  }
  
  font-family: Montserrat, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 32px;
  line-height:1;
  padding:10px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  box-shadow: rgba(0,0,0,0.5) 0 0 6px 0;
  
  ${bgVariants};
`;


const LicenseDot = ({
  children,
  variant,
  mobileSmall,
}: ILicenseDot) => (
  <LicenseDotStyled variant={variant} mobileSmall={mobileSmall}>
    {children}
  </LicenseDotStyled>
)

LicenseDot.defaultProps = {
  mobileSmall: false,
}

export default LicenseDot;
