
import * as React from "react";
import styled from 'styled-components';

import Container from "../../atoms/Container/Container";
import Text from "../../atoms/Text/Text";
import {SocialMenu} from "../../molecules/SocialMenu/SocialMenu";

import media from "../../utilities/media";
import {headline3, footertext} from "../../typography";

// @ts-ignore
import logo from "../../../images/logo_vlm_black.png";
// @ts-ignore
import ivmLogo from "../../../images/logo_ivm.png";



// @ts-ignore
import profile from "../../../images/portrait_jessica.png";


type IFooter = {}

const FooterStyled = styled.footer`
  width: 100%;
  background-color: #eee;
  position: relative;
  
  &:before {
    content:"";
    display: block;
    position: absolute;
    left: 0;
    top:0;
    width: 50%;
    height: calc(100% - 60px);
    background-color: #fff;
  }
`;

const FooterMain = styled.div`
  background-color: #fff;
  ${media.moreThan("lg")} {
    display: flex;
  }

`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: stretch;
`;

const LogoContainer = styled.div`
  //height: 100%;
  padding:20px 0;
  position: relative;
  display: flex;
  flex-direction:column;
  align-items: center;

  ${media.moreThan("lg")} {
    flex-direction:row;
    justify-content: center;
    align-items: stretch;
    padding: 20px 0;
    flex: 1 1 auto;
  }
`;

const LogoLink = styled.a`
`;

const Logo = styled.img`
  display: block;
  width: 150px;
  max-width: unset;
  height: auto;
  ${media.moreThan("lg")} {
    height: 52px;
    width: 220px;
  }
`;

const IvmLogoContainer = styled.div`
  display: block;
  padding: 20px 10px 30px;
  
  ${media.moreThan("lg")} {
    position: absolute;
    top:0;
    left:0;
    padding: 30px 10px 10px 30px;
  }
`;

const IvmLogo = styled.img`
  width: 68px;

  ${media.moreThan("lg")} {
    width: 90px;
  }
`;

const ContactContainer = styled.div`
  background-color: #eee;
  padding: 40px 20px;
  width: 100%;

  ${media.moreThan("lg")} {
    order: 2;
    padding: 20px 20px 10px;
    width: 300px;
  }

`;

const Profile = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom:10px;
`;

const ProfileImg = styled.div`
  width: 50px;
`;

const ProfileName = styled.p`
  ${headline3};
  font-size: 16px;
  padding-left:20px;
`;

const ProfileText = styled(Text)`
  margin:0 auto;
  p {
    text-align: center;
    ${footertext};
    line-height: 1.5;
  }
`;

const ContactButton = styled.a`
  display: inline-block;
  background-color: #fff;
  border: 1px solid #ccc;
  text-transform: uppercase;
  padding: 8px 10px;
  border-radius: 5px;
  color: #000;
  text-decoration: none;
  
  &:hover {
    background-color: #eee;
  }
`;

const ImprintNav = styled.ul`
  list-style-type: none;
  margin:0;
  padding:10px 0;
  text-align: center;
  
  
`;

const ImprintLink = styled.a`
  display: inline-block;
  cursor: pointer;
  ${footertext};
  text-decoration: none;
  padding:0 5px;
  border-left:1px solid #888;
`;

const ImprintLi = styled.li`
  display: inline;
  
  &:first-child {
    ${ImprintLink} {
      border-left:0;
      padding-left:0;
    }
  }
`;


const FooterNav = styled.ul`
  background-color: #eee;
  list-style-type: none;
  margin: 0;
  padding: 20px 0;
  height: 60px;
  text-align: center;
  
  ${media.moreThan("lg")} {
    padding: 0;
    display: flex;
    justify-content: stretch;
  }
`;

const FooterLi = styled.li`
  ${media.moreThan("lg")} {
    width: 25%;
  }
`;

const FooterLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  ${footertext};
  text-decoration: none;
  height: 24px;
  
  ${media.moreThan("lg")} {
    padding: 30px 0;
  }
`;

const Footer = ({}: IFooter) => (
  <FooterStyled>
    <Container
      width={1400}
      contentPadding={false}
    >
      <FooterMain>
        <ContactContainer>
          <Profile>
            <ProfileImg>
              <img src={profile} alt="Foto: Jessica von VivaLaMopped"/>
            </ProfileImg>
            <ProfileName>
              Jessica von<br/> VivaLaMopped
            </ProfileName>
          </Profile>
          <ProfileText>
            <p>
              Ihr habt Fragen oder<br/>
              Anmerkungen? Dann kontaktiert<br/>
              mich jetzt:
            </p>
            <p>
              <ContactButton href="mailto:facebook@ivm-ev.de">
                facebook@ivm-ev.de
              </ContactButton>
            </p>
          </ProfileText>
        </ContactContainer>
        <FooterContainer>
          <LogoContainer>
            <LogoLink href="https://www.vivalamopped.com" target="_blank" rel="noopener" title="Startseite VivaLaMopped">
              <Logo src={logo} alt="Logo VivaLaMopped"/>
            </LogoLink>
            <SocialMenu/>
          </LogoContainer>
          <ImprintNav>
            <ImprintLi>
              <ImprintLink href="https://www.vivalamopped.com" target="_blank" rel="noopener">
                &copy; 2020 VivaLaMopped
              </ImprintLink>
            </ImprintLi>
            <ImprintLi>
              <ImprintLink href="https://www.vivalamopped.com/kontakt" target="_blank" rel="noopener">
                Kontakt
              </ImprintLink>
            </ImprintLi>
            <ImprintLi>
              <ImprintLink href="https://www.vivalamopped.com/impressum" target="_blank" rel="noopener">
                Impressum
              </ImprintLink>
            </ImprintLi>
            <ImprintLi>
              <ImprintLink href="https://www.vivalamopped.com/datenschutz" target="_blank" rel="noopener">
                Datenschutz
              </ImprintLink>
            </ImprintLi>
            <ImprintLi>
              <ImprintLink onClick={() => {
                // @ts-ignore
                return window.klaro.show();
              }}>
                Einstellungen
              </ImprintLink>
            </ImprintLi>
            <IvmLogoContainer>
              <LogoLink href="https://www.ivm-ev.de" target="_blank" rel="noopener" title="Website IVM e.V.">
                <IvmLogo src={ivmLogo} alt="Logo IVM e.V."/>
              </LogoLink>
            </IvmLogoContainer>
          </ImprintNav>
          <FooterNav>
            <FooterLi>
              <FooterLink href="https://www.vivalamopped.com" target="_blank" rel="noopener">
                Startseite
              </FooterLink>
            </FooterLi>
            <FooterLi>
              <FooterLink href="https://www.vivalamopped.com/motorrad-aber-sicher/" target="_blank" rel="noopener">
                Motorrad: Aber sicher!
              </FooterLink>
            </FooterLi>
            <FooterLi>
              <FooterLink href="/" target="_blank">
                Zweiradführerschein
              </FooterLink>
            </FooterLi>
            <FooterLi>
              <FooterLink href="https://www.vivalamopped.com/motorradgruppen-auf-facebook/" target="_blank" rel="noopener">
                Motorradgruppen
              </FooterLink>
            </FooterLi>
          </FooterNav>
        </FooterContainer>
      </FooterMain>
    </Container>
  </FooterStyled>
)

export default Footer;
