import * as React from "react";
import styled from 'styled-components';

import AnimateHeight from 'react-animate-height';

import {ReactNode} from "react";
import Headline2, {Headline2Styled} from "../../atoms/Headline2/Headline2";

import media from "../../utilities/media";

export type ILicense = {
  headline: string,
  children?: ReactNode,
  open?: boolean,
  onClick: () => void,
}

const FaqStyled = styled.div`
  padding-bottom: 10px;
  border-bottom: 2px solid #C4C4C4;
`;

const FaqHead = styled.div`
  cursor: pointer;
  padding: 20px 0 10px;
  position: relative;
  
  ${Headline2Styled} {
    margin:0;
    
    ${media.lessThan("md")} {
      font-size: 18px;
      padding-right:50px;
    }
  }
  
  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top:20px;
    right:15px;
    border-bottom: 2px solid #1F1F1F;
    border-right: 2px solid #1F1F1F;

    transition: transform 0.3s ease-in-out;
    transform: ${props => (props.open ? 'translateY(25%) rotate(-135deg)' : 'translateY(-25%) rotate(45deg)')};
    
    ${media.lessThan("md")} {
        transform: scale(0.6) ${props => (props.open ? 'translateY(25%) rotate(-135deg)' : 'translateY(-25%) rotate(45deg)')};
    }
  }
`;

const FaqContent = styled.div`
`;

const Faq = ({
  headline,
  children,
  open,
  onClick,
}: ILicense) => {
  return (
    <FaqStyled>
      <FaqHead onClick={onClick} open={open}>
        <Headline2>{headline}</Headline2>
      </FaqHead>
        <AnimateHeight
          duration={300}
          height={open ? 'auto' : 0}
        >
          <FaqContent>
            {children}
          </FaqContent>
        </AnimateHeight>
    </FaqStyled>
  );
}

Faq.defaultProps = {
  open: false,
}

export default Faq;
