import * as React from "react";
import styled from 'styled-components';

import Container from "../../atoms/Container/Container";

import media from "../../utilities/media";
// @ts-ignore
import logo from "../../../images/logo_vlm_black.png";
import {SocialContainer, SocialMenu} from "../../molecules/SocialMenu/SocialMenu";


type IHeader = {}

const HeaderStyled = styled.header`
  height: 40px;
  
  ${SocialContainer} {
    display: none;
  }
  
  ${media.moreThan("md")} {
    height: 120px;
  }
  ${media.moreThan("lg")} {    
    ${SocialContainer} {
      display: flex;
      height: 100%;
    }
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  padding:5px 0;
  display: flex;
  justify-content: center;
  align-items: stretch;

  ${media.moreThan("md")} {
    padding: 20px 0;
  }
`;

const Logo = styled.img`
  display: block;
  margin:0 auto;
  height: 100%;
`;

const Header = ({}: IHeader) => (
  <Container
    width={1400}
  >
    <HeaderStyled>
      <LogoContainer>
        <a href="https://www.vivalamopped.com" target="_blank" rel="noopener" title="Startseite VivaLaMopped">
          <Logo src={logo} alt="Logo VivaLaMopped"/>
        </a>
      </LogoContainer>
      <SocialMenu/>
    </HeaderStyled>
  </Container>
)

export default Header;
