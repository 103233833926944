import React from "react"
import {graphql} from "gatsby"
import styled from 'styled-components';

import Layout from "../components/layout"
import Img from "gatsby-image/withIEPolyfill"
import SEO from "../components/seo"
import IntroSection from '../components/organisms/IntroSection/IntroSection';
import LicenseSection from '../components/organisms/LicenseSection/LicenseSection';
import Headline from '../components/atoms/Headline/Headline';
import License from '../components/molecules/License/License';
import Headline2 from '../components/atoms/Headline2/Headline2';
import Text from '../components/atoms/Text/Text';

import typo from '../images/headline_typo.svg';
import CoverSection from '../components/organisms/CoverSection/CoverSection';
import TextSection from '../components/organisms/TextSection/TextSection';
import Header from '../components/organisms/Header/Header';
import Separator from '../components/organisms/Separator/Separator';
import FaqSection from '../components/organisms/FaqSection/FaqSection';
import Faq from '../components/molecules/Faq/Faq';
import Footer from '../components/organisms/Footer/Footer';
import media from '../components/utilities/media';

const DesktopBr = styled.br`
  display: none;
  
  ${media.moreThan("md")} {
    display: block;
  }
  
`;

const IndexPage = ({
  data
}) => {
  return (
    <Layout>
      <SEO title="Home"/>
      <Header />
      <CoverSection
        video="https://player.vimeo.com/external/420826690.hd.mp4?s=b72e691eaff28ba0eda04ef7cbc3055957621764&profile_id=175"
      >
        <img src={typo} alt="Du bist zum Motorradfahren gemacht."/>
      </CoverSection>
      <IntroSection
        headline="Dein Weg zum Motorrad&shy;führer&shy;schein startet hier."
        teaser={{
          text: 'NEU: Erfahre alles zur Führerscheinregelung B196 – Leichtkrafträder mit B!',
          link: '#license-1',
          variant: 'A1'
        }}
      >
        <p>
          Grenzenlose Freiheit, Abenteuer, Fahrspaß… Das ist es, was das Motorrad- und Rollerfahren ausmacht. Ganz egal, ob du dich
          schon lange dafür begeisterst oder erst kürzlich der Funke auf dich übergesprungen ist - es gibt immer etwas,
          das du beim Motorrad- und Rollerfahren dazu lernen kannst. Den Anfang dieser Reise machst du hier: Finde alle
          Informationen zum Motorradführerschein, Klassen und Anforderungen sowie Infos zu Fahrprüfungen und den
          gesetzlichen Grundlagen. Lust, den Führerschein zu machen? Dann melde dich bei einer Fahrschule deines
          Vertrauens an und leg los.
        </p>
      </IntroSection>
      <LicenseSection
        headline={
          <Headline>
            Führerschein&shy;klassen: <DesktopBr/>
            Das musst du wissen
          </Headline>
        }
        image={<Img
          fluid={data.image1.childImageSharp.fluid}
          objectFit="cover"
          alt=""
        />}
      >
        <License
          teaser={{
            'short': 'AM',
            'variant': 'AM',
            'headline': 'Klein\u00ADkrafträder/ -roller',
            'description': 'Ab 15 Jahren'
          }}
        >
          <Headline2>
            Klasse AM: 50 cm³, max. 45 km/h
          </Headline2>
          <Headline2>
            Welche Kleinkrafträder und Kleinkraftroller darfst du fahren?
          </Headline2>
          <Text>
            <h3>
              Zweirädrige Kleinkrafträder und Kleinkraftroller
            </h3>
            <ul>
              <li>bauartbedingte Höchstgeschwindigkeit von max. 45 km/h</li>
              <li>Verbrennungsmotor mit einem Hubraum von max. 50 cm³</li>
              <li>Bei Elektromotoren: max. Nenndauerleistung bis zu 4 kW</li>
              <li>auch mit Beiwagen</li>
            </ul>
            <h3>
              Fahrräder mit Hilfsmotor
            </h3>
            <ul>
              <li>bauartbedingte Höchstgeschwindigkeit von max. 45 km/h</li>
              <li>Elektrische Antriebsmaschine oder Verbrennungsmotor mit einem Hubraum von max. 50 cm³</li>
            </ul>
            <h3>
              Dreirädrige Kleinkrafträder und vierrädrige Leichtkraftfahrzeuge
            </h3>
            <ul>
              <li>bauartbedingte Höchstgeschwindigkeit von max. 45 km/h</li>
              <li>Fremdzündungsmotoren mit einem Hubraum von max. 50 cm³</li>
              <li>Bei anderen Verbrennungsmotoren: max. Nutzleistung bis zu 4 kW</li>
              <li>Bei Elektromotoren: max. Nenndauerleistung bis zu 4 kW</li>
              <li>Leermasse bei vierrädrigen Leichtkraftfahrzeugen: max. als 350 kg (ohne Masse der Batterien bei Elektrofahrzeugen)</li>
            </ul>
            <Headline2>
              Gibt es ein Mindestalter für die AM-Klasse?
            </Headline2>
            <p>
              Seit dem 28. Juli 2021 ist es bundesweit möglich, den Führerschein mit 15 Jahren zu machen. Damit kannst du nach bestandener Fahrprüfung innerhalb von Deutschland mit 15 Jahren Fahrzeuge der Klasse AM fahren. Im Ausland darfst du diese Fahrzeuge aber weiterhin erst mit 16 Jahren fahren!
            </p>
          </Text>
        </License>
        <License
          teaser={{
            'short': 'B196',
            'variant': 'A1',
            'headline': 'Leicht\u00ADkrafträder/ \u2011roller mit B',
            'description': 'Ab 25 Jahren mit PKW-Führerschein'
          }}
        >
          <Headline2>
            B196: 125 cm³, max. 11 kW (15 PS)
          </Headline2>
          <Headline2>
            Welche Leichtkrafträder und Leichtkraftroller darfst du fahren?
          </Headline2>
          <Text>
            <h3>
              Zweirädrige Leichtkrafträder und
              zweirädrige Leichtkraftroller
            </h3>
            <ul>
              <li>Hubraum von max. 125 cm³</li>
              <li>Motorleistung von max. 11 kW (15 PS)</li>
              <li>Verhältnis der Leistung zum Gewicht darf 0,1 kW/kg nicht übersteigen</li>
              <li>auch mit Beiwagen</li>
            </ul>
            <h3>Dreirädrige Kraftfahrzeuge</h3>
            <ul>
              <li>symmetrisch angeordnete Reifen</li>
              <li>Bei Verbrennungsmotoren: Hubraum von mehr als 50 cm³ oder bei bauartbedingter Höchstgeschwindigkeit von mehr als 45 km/h mit einer Leistung von max. 15 kW</li>
            </ul>
          </Text>
          <Headline2>
            Gibt es ein Mindestalter für B196?
          </Headline2>
          <Text>
            <p>
              Du musst mindestens 25 Jahre alt sein und den Pkw-Führerschein seit mindestens 5 Jahren
              besitzen.
            </p>
          </Text>
          <Headline2>
            Gibt es Sonderregelungen?
          </Headline2>
          <Text>
            <p>
              Ja. Du musst für die B196-Führerscheinregelung eine theoretische und praktische Ausbildung in einer
              Fahrschule über 13,5 Zeitstunden absolvieren. Eine abschließende Prüfung ist nicht nötig.
              Nach Vorlage eines Nachweises der Fahrschule kann die Ausbildung unter der Prüfnummer 196
              in den Führerschein eingetragen werden. Wichtig: Diese Regelung ist nur innerhalb Deutschlands
              und nicht im Ausland gültig! Das Fahren eines A1-Fahrzeuges mit dieser Lizenz ist außerhalb
              Deutschlands also nicht erlaubt.
            </p>
            <p>
              Der „verkürzte“ Aufstieg in die Klasse A2 ist, anders als bei der regulären A1-Klasse, bei B196
              nicht möglich. Hier müssen die vorgegebenen Theorie- und Praxisstunden in vollem Umfang
              absolviert werden.
            </p>
          </Text>
        </License>
        <License
          teaser={{
            'short': 'A1',
            'variant': 'A1',
            'headline': 'Leicht\u00ADkrafträder/ \u2011roller',
            'description': 'Ab 16 Jahren oder ab 25 Jahren mit PKW-Führerschein'
          }}
        >
          <Headline2>
            Klasse A1: 125 cm³, max. 11 kW (15 PS)
          </Headline2>
          <Headline2>
            Welche Leichtkrafträder und Leichtkraftroller darfst du fahren?
          </Headline2>
          <Text>
            <h3>
              Zweirädrige Leichtkrafträder und
              zweirädrige Leichtkraftroller
            </h3>
            <ul>
              <li>Hubraum von max. 125 cm³</li>
              <li>Motorleistung von max. 11 kW (15 PS)</li>
              <li>Verhältnis der Leistung zum Gewicht darf 0,1 kW/kg nicht übersteigen</li>
              <li>auch mit Beiwagen</li>
            </ul>
            <h3>
              Dreirädrige Kraftfahrzeuge
            </h3>
            <ul>
              <li>symmetrisch angeordnete Reifen</li>
              <li>Bei Verbrennungsmotoren: Hubraum von mehr als 50 cm³ oder bei bauartbedingter Höchstgeschwindigkeit von mehr als 45 km/h mit einer Leistung von max. 15 kW
              </li>
            </ul>
          </Text>
          <Headline2>
            Gibt es ein Mindestalter für die A1-Klasse?
          </Headline2>
          <Text>
            <p>
              Du musst mindestens 16 Jahre alt sein, um Fahrzeuge der Klasse A1 fahren zu dürfen.
            </p>
          </Text>
          <Headline2>
            Gibt es Sonderregelungen?
          </Headline2>
          <Text>
            <p>
              Ja. Seit dem 31. Dezember 2019 kannst du leichte Roller und Motorräder der Klasse A1 (125
              cm³) auch mit dem Autoführerschein fahren (siehe Infokasten B196). Dafür musst du
              mindestens 25 Jahre alt sein, den Pkw-Führerschein seit mindestens 5 Jahren besitzen und eine
              theoretische und praktische Ausbildung in einer Fahrschule über 13,5 Zeitstunden absolvieren.
              Eine abschließende Prüfung ist nicht nötig.
            </p>
            <p>
              Nach Vorlage eines Nachweises der Fahrschule kann die Ausbildung unter der Prüfnummer 196
              in den Führerschein eingetragen werden. Wichtig: Diese Regelung ist nur innerhalb Deutschlands
              und nicht im Ausland gültig! Das Fahren eines A1-Fahrzeuges mit dieser Lizenz ist außerhalb
              Deutschlands also nicht erlaubt.
            </p>
            <p>
              Beim regulären Führerschein der Klasse A1 kannst du zudem ab 18 Jahren mit verkürzter
              theoretischer und praktischer Ausbildung auf den A2-Führerschein „aufstocken“.
            </p>
          </Text>
        </License>
        <License
          teaser={{
            'short': 'A2',
            'variant': 'A2',
            'headline': 'Limitierte Krafträder/ \u2011roller',
            'description': 'Ab 18 Jahre'
          }}
        >
          <Headline2>
            Klasse A2: max. 35 kW (48 PS)
          </Headline2>
          <Headline2>
            Welche Krafträder darfst du fahren?
          </Headline2>
          <Text>
            <h3>Krafträder und Kraftroller</h3>
            <ul>
              <li>Motorleistung von max. 35 kW (48 PS)</li>
              <li>Verhältnis der Leistung zum Gewicht darf 0,2 kW/kg nicht übersteigen</li>
              <li>auch mit Beiwagen</li>
            </ul>
          </Text>
          <Headline2>
            Gibt es ein Mindestalter für die A2-Klasse?
          </Headline2>
          <Text>
            <p>
              Du musst mindestens 18 Jahre alt sein, um Fahrzeuge der Klasse A2 fahren zu dürfen.
            </p>
          </Text>
          <Headline2>
            Gibt es Sonderregelungen?
          </Headline2>
          <Text>
            <p>
              Ja. Für Motorräder, die konform der A2-Führerscheinbedingungen auf 35 kW gedrosselt werden sollen, ist die Ausgangsleistung nicht mehr frei wählbar, sondern darf max. 70 kW betragen.
            </p>
            <p>
              Für Ausnahmen davon gibt es in Deutschland einen Bestandsschutz: Wer einen A2-Führerschein mit einem Ausstellungsdatum zwischen dem 19. Januar 2013 und dem 11. Dezember 2016 besitzt, genießt einen sog. Bestandsschutz. Diesen Fahrern ist es erlaubt, in Deutschland auch auf 35 kW gedrosselte Motorräder zu fahren, deren Ausgangsleistung die 70 kW übersteigt. Wichtig: Von Fahrten ins Ausland mit diesen Maschinen wird abgeraten, da hier keine eindeutige Rechtslage besteht.
            </p>
          </Text>
        </License>
        <License
          teaser={{
            'short': 'A',
            'variant': 'A',
            'headline': 'Offene Krafträder/ \u2011roller',
            'description': 'Ab 20 Jahren mit A2 oder ab 24 Jahren per Direkteinstieg'
          }}
        >
          <Headline2>
            Klasse A: Keine Beschränkung
          </Headline2>
          <Headline2>
            Welche Krafträder darfst du fahren?
          </Headline2>
          <Text>
            <h3>
              Krafträder und Kraftroller
            </h3>
            <ul>
              <li>Hubraum von mehr als 50 cm³</li>
              <li>bei bauartbedingter Höchstgeschwindigkeit von mehr als 45 km/h mit einer Leistung von mehr als 15 kW</li>
              <li>auch mit Beiwagen</li>
            </ul>
            <h3>
              Dreirädrige Kraftfahrzeuge
            </h3>
            <ul>
              <li>symmetrisch angeordnete Reifen</li>
              <li>Bei Verbrennungsmotoren: Hubraum von mehr als 50 cm³ oder bei bauartbedingter Höchstgeschwindigkeit von
                mehr als 45 km/h mit einer Leistung von mehr als 15 kW</li>
            </ul>
          </Text>
          <Headline2>
            Gibt es ein Mindestalter für die A-Klasse?
          </Headline2>
          <Text>
            <p>
              Für den direkten Zugang zur Klasse A, bezogen auf zweirädrige Kraftfahrzeuge, musst du mindestens 24 Jahre alt sein.
              Für den Direkteinstieg in die Klasse A, bezogen auf ausschließlich dreirädrige Kraftfahrzeuge mit einer Leistung von mehr als 15 kW, musst du mindestens 21 Jahre alt sein.
            </p>
            <p>
              Wenn du schon einen A2-Führerschein besitzt und damit in die Klasse A aufsteigen möchtest, gilt bezogen auf zweirädrige und dreirädrige Kraftfahrzeuge ein Mindestalter von 20 Jahren. Zudem musst du bereits zwei Jahre lang Besitzer des Führerscheins Klasse A2 sein.
            </p>
          </Text>
        </License>
      </LicenseSection>
      <TextSection
        headline={
          <Headline>
            Der Stufen&shy;führer&shy;schein: <DesktopBr/>
            Dein Weg von A1 bis zur A-Klasse
          </Headline>
        }
        image={<Img
          fluid={data.image2.childImageSharp.fluid}
          objectFit="cover"
          alt=""
        />}
      >
        <Headline2>
          Was bringt dir der Stufenführerschein?
        </Headline2>
        <Text>
          <p>
            Der Stufenführerschein ermöglicht es Fahrern, in einem Zeitraum von jeweils zwei Jahren Erfahrungen auf einem Fahrzeug mit einer geringeren Motorleistung zu sammeln, um dann nach einer entsprechenden praktischen Prüfung auch ohne zusätzliche Ausbildung (Zeitstunden in der Fahrschule) in die nächste Leistungsklasse aufzusteigen. Der Stufenführerschein kann mit der Stufe 1 oder 2 begonnen werden:
          </p>
          <ul>
            <li><strong>Stufe 1</strong>: A1 – 11 kW (15 PS), Mindestalter: 16 Jahre</li>
            <li><strong>Stufe 2</strong>: A2 – 35 kW (48 PS), Mindestalter: 18 Jahre</li>
            <li><strong>Stufe 3</strong>: A – keine Beschränkung, Mindestalter Zweirad: 20 Jahre, Dreirad: 21 Jahre</li>
          </ul>
        </Text>
        <Headline2>
          Welche Prüfungen müssen beim Stufenführerschein absolviert werden?
        </Headline2>
        <Text>
          <p>
            Beim ersten Führerschein A1 oder A2 muss sowohl eine theoretische als auch eine praktische Ausbildung mit anschließender Prüfung erfolgen. Für den Aufstieg in die nächsthöhere Leistungsklasse wird dann nur noch eine zeitlich verkürzte praktische Prüfung benötigt. Die Dauer der Prüfung beträgt in diesem Fall 40 Minuten. Wichtig: Für die Fahrschulen besteht für den Klassenaufstieg nach Erwerb des Erstführerscheins keine Ausbildungspflicht, dementsprechend muss auch keine Ausbildungsbescheinigung zur praktischen Prüfung vorgelegt werden.
          </p>
        </Text>
        <Headline2>
          Kannst du trotzdem Fahrstunden absolvieren?
        </Headline2>
        <Text>
          <p>
            Ja! Es wird sogar empfohlen, vor der praktischen Prüfung noch einmal die eigenen Fähigkeiten zu kontrollieren und mithilfe der Fahrschule deines Vertrauens eingeschlichene Fahrfehler zu korrigieren. Auch aus finanzieller Sicht ist die Investition in ein paar Übungseinheiten gegenüber einer eventuell nicht bestandenen Prüfung und verlorenen Gebühren sinnvoll.
          </p>
        </Text>
      </TextSection>
      <Separator image={<Img
        fluid={data.image3.childImageSharp.fluid}
        objectFit="cover"
      />} />
      <FaqSection headline={
        <Headline>
          Dein Motorrad&shy;führer&shy;schein: <DesktopBr/>
          Was sonst noch wichtig ist
        </Headline>
      }>
        <Faq
          headline="Mofa-Prüfbescheinigung"
        >
          <Text>
            <p>
              Als nationale Regelung bleibt die Mofa-Prüfbescheinigung erhalten. Mofas dürfen weiterhin ab 15 Jahren gefahren werden und weisen bei max. 50 cm³ Hubraum eine Höchstgeschwindigkeit von 25 km/h auf. Vor Ablegen der Prüfung erfolgt eine theoretische und praktische Ausbildung.
            </p>
          </Text>
        </Faq>
        <Faq
          headline="Direkter Zugang zu den Klassen A1, A2 und A"
        >
          <Text>
            <p>
              Für den direkten Zugang zu den Klassen gilt das jeweilige Mindestalter. Als „Bewerber“ auf eine dieser Fahrerlaubnisse bist du dazu verpflichtet, deine Befähigung in einer theoretischen und einer praktischen Prüfung nachzuweisen. Voraussetzung hierzu ist die theoretische und praktische Ausbildung in einer Fahrschule.
            </p>
          </Text>
        </Faq>
        <Faq
          headline="Einschlüsse und Berechtigungen"
        >
          <Text>
            <ul>
              <li>Die Fahrerlaubnis der Klasse B berechtigt auch zum Führen von Fahrzeugen der Klasse AM.</li>
              <li>Die Fahrerlaubnis der Klasse A1 berechtigt auch zum Führen von Fahrzeugen der Klasse AM.</li>
              <li>Die Fahrerlaubnis der Klasse A2 berechtigt auch zum Führen von Fahrzeugen der Klassen A1 und AM.</li>
              <li>Die Fahrerlaubnis der Klasse A berechtigt auch zum Führen von Fahrzeugen der Klassen AM, A1 und A2.</li>
            </ul>
          </Text>
        </Faq>
        <Faq
          headline="Besitzstandswahrung für alte Führerscheine"
        >
          <Text>
            <p>
              Die Berechtigungen von Fahrerlaubnissen, die bis einschließlich des 18. Januar 2013 erteilt worden sind (nach altem Recht) bleiben wie bisher bestehen und erstrecken sich auf den Umfang der ab dem 19. Januar 2013 geltenden Fahrerlaubnisse (neues Recht). Das bedeutet: Alles, was du bis zum 18.01.2013 fahren durftest, darfst du danach auch noch fahren!
            </p>
            <p>
              Wichtig: Wer seinen „alten“ Führerschein jetzt gegen das neue Kartenformat eintauscht, wird nun plötzlich für die Klassen A1 und A Eintragungen auf seiner neuen Karte vorfinden, obwohl man ja eigentlich nur den Pkw-Führerschein besitzt.
            </p>
            <p>
              Wir möchten an dieser Stelle ausdrücklich darauf hinweisen, dass die Schlüsselzahlen in der Spalte 12 immer maßgeblich sind. Wer seinen Pkw-Führerschein der Klasse B vor dem 18.01.2013 gemacht hat, darf nämlich dreirädrige Kraftfahrzeuge damit führen. Dies hat sich aber seit dem 19.01.2013 für Führerscheinneulinge geändert. Deshalb finden sich bei der Umschreibung auch der „alten“ Führerscheine folgende Schlüsselnummern, die sich aber nicht auf einspurige Motorräder und Roller beziehen:
            </p>
            <ul>
              <li>79.03 Nur dreirädrige Fahrzeuge</li>
              <li>79.04 Nur Fahrzeugkombinationen aus dreirädrigen Fahrzeugen und einem Anhänger mit einer zulässigen
                Gesamtmasse von höchstens 750 kg</li>
            </ul>
          </Text>
        </Faq>
        <Faq
          headline="Umstellung von Fahrerlaubnissen alten Rechts"
        >
          <Text>
            <ul>
              <li>Die alte Fahrerlaubnis der Klasse 1b berechtigt zum Führen von Fahrzeugen der Klassen A1, AM.</li>
              <li>Die alte Fahrerlaubnis der Klasse 4 (vor dem 01.04.1980 erteilt) berechtigt zum Führen von Fahrzeugen
                der Klassen A1, AM.</li>
              <li>Die alte Fahrerlaubnis der Klasse 3 (vor dem 01.04.1980 erteilt) berechtigt zum Führen von Fahrzeugen
                der Klassen A1, AM.</li>
            </ul>
          </Text>
        </Faq>
        <Faq
          headline="Einschlüsse oder Erweiterungen"
        >
          <Text>
            <p>
              Die Prüfziffer 196 ist eine Erweiterung des Pkw-Führerscheins Klasse B. Nach einer vorherigen theoretischen und praktischen Ausbildung (insgesamt 13,5 Zeitstunden) dürfen Pkw-Fahrer, die älter als 25 Jahre sind und seit mindestens 5 Jahren den Pkw-Führerschein besitzen, Fahrzeuge der Klasse A1 (Leichtkrafträder bis 125 ccm) fahren. Die Regelung ist nur innerhalb Deutschlands gültig und verliert im Ausland ihre Gültigkeit.
            </p>
            <p>
              Dreiradkraftfahrzeuge gelten für die Fahrerlaubnis der Klassen 3 und B (vor dem 18.01.2013 erteilt) nach nationalem Recht als Kraftwagen (Pkw) und dürfen folglich mit diesen Führerscheinen gefahren werden. Für ab dem 19.01.2013 erteilte Fahrerlaubnisse werden dreirädrige Fahrzeuge den zweirädrigen Fahrzeugen zugeordnet, also wird eine Zweiradlizenz benötigt.
            </p>
            <p>
              Die Fahrerlaubnis der Klasse B erteilt nach dem 19.01.2013 berechtigt auch zum Führen von dreirädrigen Kraftfahrzeugen im Inland, im Falle eines Kraftfahrzeuges mit einer Motorleistung von mehr als 15 kW jedoch nur, soweit der Inhaber der Fahrerlaubnis mindestens 21 Jahre alt ist.
            </p>
            <p>
              Inhaber einer Fahrerlaubnis der alten Klasse 3 oder einer ihr entsprechenden Fahrerlaubnis, die bis zum 31. März 1980 erteilt worden ist, wird die Fahrerlaubnis der Klasse A2 unter der Voraussetzung erteilt, dass sie ihre Befähigung in einer praktischen Prüfung nachgewiesen haben.
            </p>
            <p>
              Durch die Umstellung der alten Fahrerlaubnisklassen 4 (vor dem 01.04.1980 erteilt) und 1b auf die neuen Klassen A1, AM, besitzen die Inhaber dieser Klassen das Fahrerlaubnis-Äquivalent A1 bereits länger als 2 Jahre und haben somit die Einstiegsklasse in den Stufenführerschein absolviert. Der Logik folgend wird auch hier die Fahrerlaubnis der Klasse A2 unter der Voraussetzung erteilt, dass die Befähigung in einer praktischen Prüfung nachgewiesen wird. Nach 2 Jahren Besitz der Klasse A2 wäre dann der Aufstieg in die offene Klasse A durch das Bestehen der praktischen Prüfung aus dem Stufenführerschein möglich.
            </p>
          </Text>
        </Faq>
        <Faq
          headline="Übergangsregelungen"
        >
          <Text>
            <p>
              Wer vor dem 19.01.2013 die Klasse A1 erworben hat, darf ab dem 19.01.2013 auch die neuen Fahrzeuge der Klasse A1 führen. Nach Ablauf von zwei Jahren Besitz der Klasse A1 erfolgt der Aufstieg in die Klasse A2 nach dem 19.01.2013 auch gemäß den neuen Regeln durch das Bestehen einer praktischen Prüfung.
            </p>
            <p>
              Wer vor dem 19.01.2013 die Klasse A1 erworben hat, nach dem 19.01.2013 und vor Ablauf der zweijährigen Frist schon 18 Jahre alt wird und somit das neue Mindestalter für die Klasse A2 erreicht hat, muss trotzdem die zweijährige Frist zum Aufstieg einhalten. Alternativ kann der Bewerber diese Frist aber verkürzen. Hierzu müssen einige Theoriestunden sowie sechs Sonderfahrten gemäß der Fahrschülerausbildungsverordnung mit einer anschließenden praktischen Prüfung absolviert werden.
            </p>
            <p>
              Wer vor dem 19.01.2013 die Klasse A beschränkt erworben hat, darf ab dem 19.01.2013 auch die neuen Fahrzeuge der Klasse A2 führen. Nach Ablauf von zwei Jahren Besitz der Klasse A beschränkt erfolgt der automatische Aufstieg in die unbeschränkte Klasse A.
            </p>
            <p>
              Wer vor dem 19.01.2013 die Klasse A beschränkt erworben hat, nach dem 19.01.2013 und vor Ablauf der zweijährigen Frist schon 24 Jahre alt wird und somit das neue Mindestalter für die offene Klasse A erreicht hat, muss trotzdem die zweijährige Frist zum automatischen Aufstieg einhalten. Alternativ kann der Bewerber diese Frist aber verkürzen. Hierzu müssen einige Theoriestunden sowie sechs Sonderfahrten gemäß der Fahrschülerausbildungsverordnung inklusive einer anschließenden praktischen Prüfung absolviert werden.
            </p>
          </Text>
        </Faq>
      </FaqSection>
      <Footer/>
    </Layout>
  );
}

export const query = graphql`
    query {
      image1: file(relativePath: { eq: "roller_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "roller_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "roller_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

export default IndexPage
